var $class="se2--utilities",$name="utilities",$path="app/components/utilities/getTabsInlinePadding.js",$this={$class,$name,$path,};import { css } from 'configs';

import getCSSVariable from 'app/utilities/getCSSVariable';
import parseDecimal from 'app/utilities/parseDecimal';

const getTabsInlinePadding = (element) => parseDecimal(getCSSVariable(
  css.variableNames.tabsInlinePadding,
  element,
));

export default getTabsInlinePadding;

var $class="se2--navigation-customer-journey",$name="NavigationCustomerJourney",$path="app/components/NavigationCustomerJourney/utilities/onNavigationKeyDown.js",$this={$class,$name,$path,};import settings from 'app/modules/shell/settings';

import { tabClassName } from 'app/components/NavigationBarCustomerJourneyJumpTo/config';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';

import { css, directions, keyNames } from 'configs';

function onNavigationKeyDown({
  event,
  nextButton,
  prevButton,
  setInitialTabIndexes,
}) {
  if (isUsingKeyboard()) {
    const { code, target, currentTarget: tabsContainer } = event;
    const { tab } = keyNames;
    let { arrowRight, arrowLeft } = keyNames;

    if (settings.direction === directions.rtl) {
      arrowRight = keyNames.arrowLeft;
      arrowLeft = keyNames.arrowRight;
    }

    if (code === arrowRight) {
      event.preventDefault();
      const nextElement = target.parentNode.nextElementSibling;
      if (nextElement) {
        switchFocusToTargetElement(nextElement.querySelector('a'), target);
        nextButton.click();
      }
    } else if (code === arrowLeft) {
      event.preventDefault();
      const previousElement = target.parentNode.previousElementSibling;
      if (previousElement) {
        switchFocusToTargetElement(previousElement.querySelector('a'), target);
        prevButton.click();
      }
    } else if (code === tab) {
      const activeTab = tabsContainer.querySelector(`.${css.classNames.active}`);
      if (activeTab) {
        const onFocusOut = ({ currentTarget }) => {
          setInitialTabIndexes(tabsContainer, activeTab, tabClassName);
          currentTarget.removeEventListener('focusout', onFocusOut);
        };
        target.addEventListener('focusout', onFocusOut);
      }
    }
  }
}

export default onNavigationKeyDown;

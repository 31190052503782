var $class="se2--utilities",$name="utilities",$path="app/components/utilities/toggleArrowsVisibility.js",$this={$class,$name,$path,};import getTabsInlinePadding from 'app/components/utilities/getTabsInlinePadding';
import toggleElementDisplay from 'app/utilities/toggleElementDisplay';

import settings from 'app/modules/shell/settings';

import { directions } from 'configs';

function toggleArrowsVisibility(targetElement, prevButton, nextButton) {
  const tabsElementPaddingValue = getTabsInlinePadding(targetElement);
  const numberOfPaddings = 2;
  const actualTabsWidth = targetElement.scrollWidth - tabsElementPaddingValue * numberOfPaddings;

  if (settings.direction === directions.rtl) {
    const conditionForPrevButton = targetElement.scrollLeft + tabsElementPaddingValue < 0;
    const conditionForNextButton = Math.ceil(Math.abs(targetElement.scrollLeft) + targetElement.offsetWidth
    - tabsElementPaddingValue) < actualTabsWidth;
    toggleElementDisplay(prevButton, conditionForPrevButton);
    toggleElementDisplay(nextButton, conditionForNextButton);
  } else {
    const conditionForPrevButton = Math.floor(targetElement.scrollLeft) - tabsElementPaddingValue > 0;
    const conditionForNextButton = Math.ceil(targetElement.scrollLeft + targetElement.offsetWidth
      - tabsElementPaddingValue) < actualTabsWidth;
    toggleElementDisplay(prevButton, conditionForPrevButton);
    toggleElementDisplay(nextButton, conditionForNextButton);
  }
}

export default toggleArrowsVisibility;

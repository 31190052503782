var $class="se2--navigation-bar-customer-journey-jump-to",$name="NavigationBarCustomerJourneyJumpTo",$path="app/components/NavigationBarCustomerJourneyJumpTo/index.js",$this={$class,$name,$path,};import onNavigationKeyDown from 'app/components/NavigationCustomerJourney/utilities/onNavigationKeyDown';
import onTabArrowClick, { nextButtonClassName, prevButtonClassName }
  from 'app/components/NavigationCustomerJourney/utilities/onTabArrowClick';
import setInitialTabIndexes from 'app/components/NavigationCustomerJourney/utilities/setInitialTabIndexes';
import toggleArrowsVisibility from 'app/components/utilities/toggleArrowsVisibility';

import shell from 'app/modules/shell';

import {
  tabClassName,
  tabsClassName,
} from './config';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const scrollingElement = element.querySelector(`.${tabsClassName}`);
    const prevButton = element.querySelector(`.${prevButtonClassName}`);
    const nextButton = element.querySelector(`.${nextButtonClassName}`);

    addEventListener(
      prevButton,
      'click',
      (event) => onTabArrowClick(event, scrollingElement, tabClassName),
    );

    addEventListener(
      nextButton,
      'click',
      (event) => onTabArrowClick(event, scrollingElement, tabClassName),
    );

    addEventListener(
      scrollingElement,
      'scroll',
      () => toggleArrowsVisibility(scrollingElement, prevButton, nextButton),
    );

    addEventListener(
      element,
      'keydown',
      (event) => onNavigationKeyDown({
        addEventListener,
        event,
        nextButton,
        prevButton,
        setInitialTabIndexes,
      }),
    );

    // Buttons initialization
    toggleArrowsVisibility(
      scrollingElement,
      prevButton,
      nextButton,
    );

    addEventListener(window, 'resize', () => toggleArrowsVisibility(scrollingElement, prevButton, nextButton));
  });
});

var $class="se2--navigation-customer-journey",$name="NavigationCustomerJourney",$path="app/components/NavigationCustomerJourney/utilities/onTabArrowClick.js",$this={$class,$name,$path,};import getTabsInlinePadding from 'app/components/utilities/getTabsInlinePadding';

import settings from 'app/modules/shell/settings';

import { directions } from 'configs';

export const arrowWidth = 32;
export const nextButtonClassName = 'arrow-next';
export const prevButtonClassName = 'arrow-prev';

const scrollElement = (element, scrollValue) => {
  element.scroll({ left: settings.direction === directions.rtl ? -(scrollValue) : scrollValue });
};

const onTabArrowClick = ({ currentTarget }, scrollingElement, tabClassName) => {
  const scrollingElementWidth = scrollingElement.offsetWidth;
  const tabsInlinePadding = getTabsInlinePadding(scrollingElement);
  const tabsScrollLeft = Math.abs(scrollingElement.scrollLeft);
  const tabs = scrollingElement.querySelectorAll(`.${tabClassName}`);
  const firstTab = tabs[0];
  const lastTab = tabs[tabs.length - 1];
  let targetTabOffset = 0;

  if (currentTarget.classList.contains(nextButtonClassName)) {
    let lastIncludedTab;

    tabs.forEach((tab) => {
      if (targetTabOffset < scrollingElementWidth + tabsScrollLeft) {
        targetTabOffset += tab.offsetWidth;
        lastIncludedTab = tab;
      }
    });

    const scrollValue = targetTabOffset
      - scrollingElementWidth
      + arrowWidth
      + (lastIncludedTab === lastTab ? 2 * tabsInlinePadding : tabsInlinePadding);

    scrollElement(scrollingElement, scrollValue);
  }

  if (currentTarget.classList.contains(prevButtonClassName)) {
    let lastIncludedTab;

    tabs.forEach((tab) => {
      if (targetTabOffset + tabsInlinePadding < tabsScrollLeft) {
        targetTabOffset += tab.offsetWidth;
        lastIncludedTab = tab;
      }
    });

    if (!lastIncludedTab) return;

    const scrollValue = targetTabOffset
      - lastIncludedTab.offsetWidth
      - arrowWidth
      + (lastIncludedTab === firstTab ? 0 : tabsInlinePadding);

    scrollElement(scrollingElement, scrollValue);
  }
};

export default onTabArrowClick;

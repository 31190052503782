var $class="se2--navigation-customer-journey",$name="NavigationCustomerJourney",$path="app/components/NavigationCustomerJourney/utilities/setInitialTabIndexes.js",$this={$class,$name,$path,};import setTabIndex from 'app/utilities/setTabIndex';

import { css } from 'configs';

import forEach from 'lodash/forEach';

const setInitialTabIndexes = (tabsContainer, activeTab, tabClassName) => {
  const notActiveTabs = tabsContainer.querySelectorAll(`.${tabClassName}:not(.${css.classNames.active})`);
  forEach(notActiveTabs, (tab) => setTabIndex(tab.querySelector('a'), false));
  setTabIndex(activeTab.querySelector('a'), true);
};

export default setInitialTabIndexes;
